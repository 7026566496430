<template>
  <div style="min-height: 120px;">
    <!-- Firstname -->
    <div class="p_space">
      <span>{{$t('common.person.firstname')}}: </span>
      <span>{{person.firstname}}</span>
    </div>

    <div class="p_space">
      <span>{{$t('common.person.name')}}: </span>
      <span>{{person.name}}</span>
    </div>

    <div class="p_space">
      <span>{{$t('common.person.email')}}: </span>
      <span>{{person.email}}</span>
    </div>

    <div class="p_space">
      <span>{{$t('common.person.phone')}}: </span>
      <span>{{person.phone}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Person",

  props: {
    person: {
      type: Object,
      default: () => {
        return {
          firstname: "",
          name: "",
          email: "",
          phone: ""
        }
      }
    }
  }
}
</script>

<style scoped>

</style>